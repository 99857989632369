<template>

  <app-collapse
    accordion
    type="margin"
    class="mt-1"
  >
    <!--Search-->
    <app-collapse-item
      title="Search"
      :is-visible="true"
    >
      <template #header>
        <div
          class="d-flex justify-content-start align-items-center"
        >
          <span v-if="totalUsers!==null">
            {{ $tc("referral.referrals_found", totalUsers) }}
          </span>
          <span v-else>{{ $t('referral.referrals_finder') }}</span>
          <b-spinner
            v-if="submittingFetch"
            small
            type="grow"
            variant="secondary"
            class="ml-1"
          />
        </div>

      </template>
      <b-row class="mb-1">
        <b-col>
          <!--        <b-input-group>-->
          <!--          <b-form-input-->
          <!--            v-model="q"-->
          <!--            :placeholder="$t('Search')"-->
          <!--            class="search-point"-->
          <!--          />-->
          <!--        </b-input-group>-->
          <select-purposes :filters="filters" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>

          <div class="d-md-none d-flex ">
            <b-form-checkbox
              v-model="filters.geolocationIsActive"
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon
                  icon="CrosshairIcon"
                />
              </span>
              <span class="switch-icon-right">

                <feather-icon
                  icon="CrosshairIcon"
                  @click="updateUserCountryData()"
                />

              </span>
            </b-form-checkbox>
            {{ getUserAddressFormattedShort }}
          </div>

          <b-input-group class="d-none d-md-flex">
            <b-input-group-prepend is-text>
              <b-form-checkbox
                v-model="filters.geolocationIsActive"
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon
                    icon="MapPinIcon"
                  />
                </span>
                <span class="switch-icon-right">
                  <feather-icon
                    icon="MapPinIcon"
                  />
                </span>

              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input
              :value="getUserAddressFormattedShort"
              placeholder=""
              class="search-point"
              disabled
            />
            <b-input-group-append>
              <b-button variant="primary">
                <feather-icon
                  icon="CrosshairIcon"
                  @click="updateUserCountryData()"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-form-group
            v-if="filters.geolocationIsActive"
            class="py-1 px-2"
          >
            <vue-slider
              id="serviceRadius"
              v-model="locationRadius"
              :direction="direction"
              :min="1000"
              :max="100000"
              :interval="1000"
              :tooltip="'always'"
              :tooltip-formatter="`${serviceRadius}`+ this.$t('km')"
              :tooltip-placement="'bottom'"
              @drag-end="()=>setFilterRadius()"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </app-collapse-item>
  </app-collapse>

</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import store from '@/store'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { useUserLocation } from '@/views/apps/user/useUser'
import VueSlider from 'vue-slider-component'
import SelectPurposes from '@/views/apps/service/offer/list/SelectPurposes.vue'

export default {
  components: {
    // AppCollapseItem,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
    // 3rd Party
    VueSlider,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    SelectPurposes,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    totalUsers: {
      type: Number,
      required: false,
      default: () => 0,
    },
    submittingFetch: {
      type: Boolean,
      required: false,

    },
  },
  data() {
    return {
      options: this.filterOptions,
      locationRadius: 25000,
      q: '',
    }
  },
  computed: {
    serviceRadius() {
      let km = 0
      const radius = this.locationRadius
      if (radius) {
        km = Math.floor(radius / 1000)
      }
      return km
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    q(newVal, oldVal) {
      this.setSearchQuery(newVal)
    },
  },

  methods: {
    setSearchQuery(val) {
      if (val.length > 3) {
        this.filters.q = this.q
      }
    },
    setFilterRadius() {
      this.filters.locationRadius = this.locationRadius
    },

  },
  setup() {
    const { getUserAddressFormattedShort, updateUserCountryData } = useUserLocation()

    return {
      getUserAddressFormattedShort,
      updateUserCountryData,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';

</style>

<style scoped lang="scss">
.search-point.form-control{
  height: auto;
}
.input-group-prepend{
  border: 1px solid #d8d6de;
  border-radius: 0.357rem 0 0 0.357rem;
}
</style>
