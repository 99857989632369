<template>
  <div>
    <!-- form -->
    <validation-observer
      ref="authForm"
      #default="{invalid}"
    >
      <b-form
        id="register"
        class="auth-register-form mt-2"
        @submit.prevent="submit"
      >
        <!-- name -->
        <b-form-group
          label-for="name"
          :label="$t('auth.Name')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.Name')"
            rules="required|min:4"
          >
            <b-form-input
              id="name"
              ref="name"
              v-model="userName"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!--phone-->
        <b-form-group
          :label="$t('auth.Phone')"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.Phone')"
            vid="phone"
            rules="required"
          >
            <VuePhoneNumberInput
              id="phone"
              v-model="phone.number"
              :error="phone.valid!==true"
              name="phone"
              valid-color="#28C76F"
              :required="true"
              :translations="{
                countrySelectorLabel: $t('Country code'),
                countrySelectorError: $t('Choose a country'),
                phoneNumberLabel: $t('Phone Number'),
                example: $t('Example:')
              }"
              :only-countries="countriesList"
              :default-country-code="countryCode"
              @update="onPhoneUpdate"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <div v-if="phone.valid">
              <small />
            </div>
          </validation-provider>
        </b-form-group>
        <!-- email -->
        <b-form-group
          label="Email"
          label-for="register-email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="email"
          >
            <b-form-input
              id="register-email"
              v-model="userEmail"
              name="register-email"
              :state="errors.length > 0 ? false:null"
              placeholder="john@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group
          label-for="password"
          :label="$t('auth.Password')"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('Password')"
            rules="min:8|required|confirmed:@confirmation"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false:null"
                placeholder="········"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password confirmation -->
        <b-form-group
          v-if="password"
          label-for="password-confirmation"
          :label="$t('auth.Password Confirmation')"
        >
          <validation-provider
            v-slot="{ errors }"
            :name="$t('auth.Password Confirmation')"
            rules="required"
            vid="confirmation"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password-confirmation"
                v-model="passwordConfirmation"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false:null"
                placeholder="········"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          variant="primary"
          block
          type="submit"
          :disabled="invalid||submitting||!phone.valid "
        >
          <span v-if="submitting">
            <b-spinner
              type="grow"
              small
            />
            {{ $t('Loading...') }}
          </span>
          <span v-if="!submitting">
            {{ getRegisterButtonText() }}
          </span>

        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>

import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import {
  required, email, min, digits,
} from '@validations'
import {
  BButton, BForm, BFormGroup, BFormInput, BSpinner, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { showToast } from '@/mixins/notification/toasts'
import { numbersOnly, translateApiErrors } from '@/mixins/functions'
import i18n from '@/libs/i18n'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import store from '@/store'
import { currency, locales } from '@/mixins/options'

extend('confirmed', {
  params: ['target'],
  validate(value, { target }) {
    return target ? value === target : true
  },

  message: i18n.t('auth.password_confirmation_does_not_match'),
})
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility, showToast, numbersOnly],

  props: {
    registerButtonText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },
      submitting: false,
      userName: '',
      userEmail: '',
      userPhone: null,
      newUserPhone: '',
      password: '',
      passwordConfirmation: '',
      // validation
      required,
      email,
      min,
      digits,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    countryCode() {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode || 'UA'
    },

  },

  created() {
    this.$nextTick(() => this.$refs.name.focus())
  },
  methods: {

    getRegisterButtonText() {
      return this.registerButtonText || this.$t('auth.Sign up')
    },

    onPhoneUpdate({
      countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber,
    }) {
      this.phone.valid = isValid
      if (isValid) {
        this.userPhone = formattedNumber
      }
    },
    getFormattedPhone() {
      return this.userPhone ? this.userPhone.replace(/\+/g, '') : ''
    },

    register() {
      this.submitting = true
      store.dispatch('user/createUserManaged', {
        name: this.userName,
        email: this.userEmail,
        phone: this.getFormattedPhone(),
        lang: i18n.locale,
        currency: this.getCurrency(),
        password: this.password,
      }).then(response => {
        if (response.status === 201) {
          this.showToast(response, 'User')
          this.$emit('user-managed-created', response.data)
        }
      })
        .catch(error => {
          this.$emit('registration-error')
          const { errors } = error.response.data
          translateApiErrors(errors)
          this.$refs.authForm.setErrors(
            errors,
          )
        })
        .finally(() => {
          setTimeout(() => {
            this.submitting = false
          }, 1000)
        })
    },
    submit() {
      this.$refs.authForm.validate().then(success => {
        if (success) {
          this.register()
        }
      })
    },
  },
  setup() {
    const { getCurrency } = currency()
    const { countriesList } = locales()
    return {
      getCurrency,
      countriesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.vue-phone-number-input .select-country-container {
  min-width: 90px!important;
  max-width: 90px!important;
}
.country-selector__input {
  padding-right: 0!important;
}
</style>
<style scoped>

</style>
