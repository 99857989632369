<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <app-collapse
        class="sidebar-shop filter"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <div
          v-if="mqShallShowLeftSidebar"
          class="sidebar-header"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            class="close-button"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </div>

        <!-- Filters' Card -->
        <!-- Overlay -->
        <b-overlay
          :show="submittingFetch"
          rounded
          opacity="0.6"
          spinner-medium
          spinner-variant="primary"
          spinner-type="grow"
        >

          <!-- Multi Range -->
          <!--          <div class="multi-range-price">-->
          <!--            <h6 class="filter-title mt-0">-->
          <!--              Multi Range-->
          <!--            </h6>-->
          <!--            <b-form-radio-group-->
          <!--              v-model="filters.priceRangeDefined"-->
          <!--              class="price-range-defined-radio-group"-->
          <!--              stacked-->
          <!--              :options="filterOptions.priceRangeDefined"-->
          <!--            />-->
          <!--          </div>-->

          <!-- Price Slider -->
          <!--          <div class="price-slider">-->
          <!--            <h6 class="filter-title">-->
          <!--              Price Range-->
          <!--            </h6>-->
          <!--            <vue-slider-->
          <!--              v-model="filters.priceRange"-->
          <!--              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
          <!--            />-->
          <!--          </div>-->

          <app-collapse
            class="mt-4 mt-md-0"
            type="margin"
          >
            <app-collapse-item
              title="Filters"
              :is-visible="true"
            >
              <template #header>
                <div
                  class="d-flex justify-content-between align-items-center text-primary h5"
                >
                  <feather-icon
                    icon="FilterIcon"
                    size="22"
                    class="mr-1 d-none d-lg-block"
                  />
                  <span>{{ $t('Filters') }} </span>
                </div>
              </template>
              <b-form-group class="mb-1">
                <b-form-checkbox
                  v-model="managed"
                  class="mb-1"
                >
                  <h5>
                    {{ $t('common.manageability') }}
                  </h5>
                </b-form-checkbox>
                <b-form-radio-group
                  v-model="filters.managed"
                  :disabled="!managed"
                  class="ml-1 list item-view-radio-group"
                  stacked
                >
                  <b-form-radio
                    v-for="option in managedOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    <p>{{ $tc(option.text,2) }}</p>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-1">
                <b-form-checkbox
                  v-model="serviceProviders"
                  class="mb-1"
                >
                  <h5>
                    {{ $t('Services') }}
                  </h5>
                </b-form-checkbox>
                <b-form-radio-group
                  v-model="filters.serviceProviders"
                  class="ml-1 list item-view-radio-group"
                  stacked
                  :disabled="!serviceProviders"
                >
                  <b-form-radio
                    v-for="option in serviceProvidersOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    <p>{{ $tc(option.text,2) }}</p>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-1">
                <b-form-checkbox
                  v-model="activeSubscriptions"
                  class="mb-1"
                >
                  <h5>
                    {{ $t('subscription.pricing_plans') }}
                  </h5>
                </b-form-checkbox>
                <b-form-radio-group
                  v-model="filters.activeSubscriptions"
                  class="ml-1 list item-view-radio-group"
                  stacked
                  :disabled="!activeSubscriptions"
                >
                  <b-form-radio
                    v-for="option in activeSubscriptionsOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    <p>{{ $t(option.text) }}</p>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <!-- Languages-->
              <div class="d-flex py-1">
                <feather-icon
                  icon="GlobeIcon"
                  size="19"
                />
                <p class="mb-0 ml-50">
                  {{ $t('Language') }}
                </p>
              </div>
              <b-form-group class="mb-2">
                <v-select
                  v-model="filters.languages"
                  :options="localesList"
                  :reduce="text => text.label"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                >
                  <template #option="{ name }">
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>

              <!-- Authors -->
              <!--          <div class="author">-->
              <!--            <h6 class="filter-title">-->
              <!--              Authors-->
              <!--            </h6>-->
              <!--            <b-form-checkbox-->
              <!--              v-model="filters.userId"-->
              <!--              :value="options.userId"-->
              <!--              class="custom-control-primary"-->
              <!--            >-->
              <!--              Me-->
              <!--            </b-form-checkbox>-->
              <!--          </div>-->

              <!-- Ratings -->
              <!--          <div class="ratings">-->
              <!--            <h6 class="filter-title">-->
              <!--              Ratings-->
              <!--            </h6>-->
              <!--            <div-->
              <!--              v-for="rating in filterOptions.ratings"-->
              <!--              :key="rating.rating"-->
              <!--              class="ratings-list"-->
              <!--            >-->
              <!--              <b-link>-->
              <!--                <div class="d-flex">-->
              <!--                  <feather-icon-->
              <!--                    v-for="star in 5"-->
              <!--                    :key="star"-->
              <!--                    icon="StarIcon"-->
              <!--                    size="17"-->
              <!--                    :class="[{'fill-current': star <= rating.rating}, star <= rating.rating ? 'text-warning' : 'text-muted']"-->
              <!--                  />-->
              <!--                  <span class="ml-25">&amp; up</span>-->
              <!--                </div>-->
              <!--              </b-link>-->
              <!--              <div class="stars-received">-->
              <!--                <span>{{ rating.count }}</span>-->
              <!--              </div>-->
              <!--            </div>-->
              <!--          </div>-->
            </app-collapse-item>

          </app-collapse>

          <!-- Purposes -->
          <ListFilterPurposes :collapsed="true" />
        </b-overlay>

      </app-collapse>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>

</template>

<script>
import {
  BFormCheckbox,
  BFormGroup,
  BOverlay,
  BFormRadioGroup,
  BFormRadio,

  VBToggle,
} from 'bootstrap-vue'

import { locales, postStatuses } from '@/mixins/options'
import Ripple from 'vue-ripple-directive'
import { useRemoteData } from '@/views/apps/user/service-providers-list/useProvidersList'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ref, watch } from '@vue/composition-api'
import ListFilterPurposes from '@/views/apps/service/offer/list/ListFilterPurposes.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BOverlay,
    BFormRadioGroup,
    BFormRadio,
    ListFilterPurposes,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [postStatuses],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {

    }
  },

  setup(props) {
    const activeSubscriptions = ref(false)
    const serviceProviders = ref(false)
    const managed = ref(false)

    const activeSubscriptionsOptions = [
      { text: 'subscription.no_active_subscriptions', value: 'none' },
      { text: 'subscription.active_subscriptions', value: 'active' },
    ]
    const serviceProvidersOptions = [
      { text: 'user.provide_services', value: 'service_provider' },
      { text: 'user.no_provide_services', value: 'none' },
    ]
    const managedOptions = [
      { text: 'user.managed', value: true },
      { text: 'user.no_managed', value: false },
    ]
    const { localesList } = locales()

    const {
      submittingFetch,
    } = useRemoteData()

    function clearFilterManaged() {
      props.filters.managed = null
    }
    function clearFilterServiceProviders() {
      props.filters.serviceProviders = null
    }
    function clearFilterActiveSubscriptions() {
      props.filters.activeSubscriptions = null
    }
    watch(managed, val => {
      if (!val) {
        clearFilterManaged()
      }
    })
    watch(serviceProviders, val => {
      if (!val) {
        clearFilterServiceProviders()
      }
    })
    watch(activeSubscriptions, val => {
      if (!val) {
        clearFilterActiveSubscriptions()
      }
    })
    return {
      submittingFetch,
      localesList,
      activeSubscriptions,
      activeSubscriptionsOptions,
      serviceProviders,
      serviceProvidersOptions,
      managed,
      managedOptions,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" >
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';
@import '~@core/scss/base/components/include'; // Components includes
.checkbox-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.sidebar-shop .sidebar-header .close-button{
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}
.sidebar-shop.filter.show {
  overflow: auto;
  background-color: $white;
}

.dark-layout .sidebar-shop.filter.show {
  background-color: $theme-dark-card-bg;
}

.custom-control.custom-control-inline.custom-checkbox{
  display: block;
}
</style>
